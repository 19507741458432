import styled from 'styled-components'
import { COLORS } from 'ui-kit/constants'

export const SItem = styled.div`
  padding: 0 8px;
`

export const SGameProvider = styled.div`
  background: ${COLORS.dark600};
  padding: 24px;
  border-radius: 8px;
  overflow: hidden;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: ${COLORS.dark550};
  }
`

export const SImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SSliderWrapper = styled.div`
  margin: 0 -8px;
`
