import styled, { css } from 'styled-components'
import { applyStyles, SIZES } from 'context/layoutContext'


export const SSliderSection = styled.div`
  display: grid;
  grid-gap: 12px;
  margin-top: 16px;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE, SIZES.W512, SIZES.W688], theme.contentSize, css`
      grid-template-columns: 1fr;
    `)}

    ${applyStyles([SIZES.W1040], theme.contentSize, css`
      grid-template-columns: 3fr 2fr;
    `)}
  `};
`
