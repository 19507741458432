import styled, { css } from 'styled-components'
import { Button } from 'ui-kit'
import { SIZES, applyStyles } from 'context/layoutContext'
import { BREAKPOINTS } from 'ui-kit/constants'

interface ContentPageProps {
  contentSize: SIZES
}

export const SSliderWrapper = styled.div`
  position: relative;
  z-index: 1;
`

export const SButton = styled(Button)`
  min-width: 32px;
  box-sizing: border-box;
  position: absolute;
  height: 32px;
  width: 32px;
  z-index: 2;
`

export const SLeftButton = styled(SButton)`
  left: 16px;
  top: 134px;
  opacity: 0;
  transition: 0.2s;
`

export const SRightButton = styled(SButton)`
  right: 16px;
  top: 134px;
  opacity: 0;
  transition: 0.2s;
`

export const SBonusesWrapper = styled.div<ContentPageProps>`
  background: #1D1E25;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  &:hover ${SLeftButton}, &:hover ${SRightButton}{
    opacity: 1;
  }

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE, SIZES.W512], theme.contentSize, `
      margin: 0 12px;
      border-radius: 0 0 8px 8px;
    `)}
  `};
`

export const SArrowLeft = styled.div`
  transform: rotate(90deg);
  display: flex;
  align-items: center;
`

export const SArrowRight = styled.div`
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
`

interface SSlideProps {
  bg?: string;
}

export const SBgImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 1;

  @media (max-width: ${BREAKPOINTS.xsMedium}px) {
    display: none;
  }

  &.mobile {
    @media (max-width: ${BREAKPOINTS.xsMedium}px) {
      display: block;
    }

    @media (min-width: ${BREAKPOINTS.xsMedium + 1}px) {
      display: none;
    }
  }
`

export const SSlide = styled.div<SSlideProps>`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 300px;
  box-sizing: border-box;
  padding: 42px 24px;
  overflow: hidden;
  ${props => props.bg && `background-image: url('${props.bg}');`}

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE, SIZES.W512], theme.contentSize, `
      height: 178px;
      border-radius: 0 0 8px 8px;
    `)}
  `};
`

export const STitle = styled.div`
  max-width: 300px;
  font-size: 26px;
  font-weight: 900;
  line-height: 100%;
  z-index: 2;
  position: relative;
  text-transform: uppercase;
`



