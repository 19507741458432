import styled from 'styled-components'
import { BREAKPOINTS } from 'ui-kit/constants'

export const SSliderHeader = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
`

export const SSliderButtons = styled.div`
  display: flex;
  gap: 8px;
`

export const SSection = styled.div`
  @media screen and (max-width: ${BREAKPOINTS.xsMedium}px) {
    margin-bottom: -16px;
  }
`
