import { FC, useMemo, useState } from 'react'

import { useTranslation } from 'next-i18next'
import { useLayoutContext } from 'context/layoutContext'
import CardsSlider from 'components/pages-blocks/Components/CardSlider'
import { createPortal } from 'react-dom'

import ProvidersBlock from '../../for_pages/CatalogPage/CatalogToolbar/FiltersPanel/ProvidersBlock'

import { IGameProviders } from './types'
import { getSettings } from './helper'
import ProviderCard from './ProviderCard'


const Providers: FC<IGameProviders> = ({ providers }) => {
  const [openAll, setOpenAll] = useState(false)
  const openAllProviders = () => setOpenAll(true)

  const providersList = providers?.filter(provider => !!provider.imagePreviewUrl)
  const { t } = useTranslation()

  const { contentSize } = useLayoutContext()
  const settings = useMemo(() => getSettings(contentSize), [contentSize])

  return (
    <>
      <CardsSlider
        total={providersList.length}
        icon='/img/home-page/sliders-icons/providers.svg'
        title={t('main_slider_providers')}
        onTitleClick={openAllProviders}
        settings={settings}
        sliderStyles={`
          margin-right: -8px !important;
          margin-left: -8px;
        `}
      >
        {providersList?.map((provider) => (
          <ProviderCard key={provider.id}
            provider={provider}
          />
        ))}
      </CardsSlider>

      {openAll && !!document && createPortal(<ProvidersBlock providers={providers} setOpen={setOpenAll} />, document.body)}
    </>
  )
}

export default Providers
