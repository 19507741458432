import CasinoImg from 'public/img/home-page/casino-banner/casino-banner-img.png'
import { useRouter } from 'next/router'
import { Routes } from 'types/routes'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { SizeComponent, SIZES } from 'context/layoutContext'

import {
  SBannerBgElipse, STextBox, SBannerWrapper, STitle, SDescription, SImgWrapper
} from './styles'


const CasinoBanner = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const goToCasino = () => router.push(Routes.catalog)

  return (
    <SBannerWrapper onClick={goToCasino}>
      <SBannerBgElipse />

      <STextBox>
        <STitle>
          {t('home_page_casino_banner_title')}
        </STitle>

        <SizeComponent sizes={[SIZES.W688, SIZES.W1040]}>
          <SDescription>
            {t('home_page_casino_banner_description')}
          </SDescription>
        </SizeComponent>
      </STextBox>

      <SImgWrapper>
        <Image
          src={CasinoImg?.src}
          alt='Casino'
          width={298}
          height={208}
          quality={70}
        />
      </SImgWrapper>
    </SBannerWrapper>
  )
}

export default CasinoBanner
