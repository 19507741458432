import { FC } from 'react'

import { useTranslation } from 'next-i18next'
import { useLayoutContext } from 'context/layoutContext'
import CardsSlider from 'components/pages-blocks/Components/CardSlider'
import GameCard from 'components/pages-blocks/Components/GameCard'

import { getSettings } from './helper'
import { IGamesSlider } from './types'

const CrashGames: FC<IGamesSlider> = ({ games }) => {
  const linkToAll = '/catalog/category/10'
  const { t } = useTranslation()
  const { contentSize } = useLayoutContext()
  const settings = getSettings(contentSize)

  return (
    <CardsSlider
      total={games?.length || 0}
      title={t('home_page_crash_games_title')}
      icon='/img/left-menu/items/crash.png'
      linkToAll={linkToAll}
      settings={settings}
    >
      {games?.map(( game ) => (
        <GameCard key={game?.id}
          game={game}
        />
      ))}
    </CardsSlider>
  )
}

export default CrashGames
