import { Settings } from 'react-slick'
import { SIZES } from 'context/layoutContext'

export const getSettings = (contentSize: SIZES): Settings => {
  let slidesToShow = 3
  if (contentSize === SIZES.W1040) slidesToShow = 6
  if (contentSize === SIZES.W688) slidesToShow = 4

  return {
    dots: false,
    arrows: false,
    infinite: true,
    initialSlide: 0,
    speed: 300,
    slidesToShow,
    slidesToScroll: slidesToShow,
    rows: contentSize === SIZES.MOBILE ? 2 : 1,
  }
}
