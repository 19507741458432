import { FC, useMemo } from 'react'

import { useTranslation } from 'next-i18next'
import countries from 'i18n-iso-countries'
import { useAppContext } from 'context/AppContext'
import { useLayoutContext } from 'context/layoutContext'
import CardsSlider from 'components/pages-blocks/Components/CardSlider'
import GameCard from 'components/pages-blocks/Components/GameCard'

import { getSettings } from './helper'
import { IGamesSlider } from './types'
import { SSection } from './styles'

const TopGamesInCountry: FC<IGamesSlider> = ({ games }) => {
  const linkToAll = '/catalog/category/21'
  const appContext = useAppContext()
  const { t } = useTranslation()
  const countryName = countries.getName(appContext?.user?.countryIso || appContext?.countryByIp?.iso || 'US', 'en')

  const title = `${t('home_page_top_in_country_title')} ${countryName ? countryName : t('home_page_top_in_your_country')}`

  const { contentSize } = useLayoutContext()
  const settings = useMemo(() => getSettings(contentSize), [contentSize])

  return (
    <SSection>
      <CardsSlider
        total={games?.length || 0}
        title={title}
        icon='/img/home-page/sliders-icons/top-games-in_country.svg'
        linkToAll={linkToAll}
        settings={settings}
      >
        {games?.map((game) => (
          <GameCard key={game?.id}
            severalRowsInMobile
            game={game}
          />
        ))}
      </CardsSlider>
    </SSection>
  )
}

export default TopGamesInCountry
