import { SizeComponent, SIZES } from 'context/layoutContext'
import DepositBonus from 'components/pages-blocks/Components/DepositBonus'
import BonusesSlider from 'components/pages-blocks/Components/BonusesSlider'

import { SSliderSection } from './styles'

const SliderSection = () => {
  return (
    <SSliderSection>
      <DepositBonus />

      <SizeComponent sizes={[SIZES.W1040]}>
        <BonusesSlider />
      </SizeComponent>
    </SSliderSection>
  )
}

export default SliderSection
