import { FC, useMemo } from 'react'

import { useTranslation } from 'next-i18next'
import { useLayoutContext } from 'context/layoutContext'
import CardsSlider from 'components/pages-blocks/Components/CardSlider'
import GameCard from 'components/pages-blocks/Components/GameCard'

import { getSettings } from './helper'
import { IGamesSlider } from './types'

const RichyGames: FC<IGamesSlider> = ({ games }) => {
  const linkToAll = '/catalog/category/richy'
  const { t } = useTranslation()
  const { contentSize } = useLayoutContext()
  const settings = useMemo(() => getSettings(contentSize), [contentSize])

  return (
    <CardsSlider
      total={games?.length || 0}
      icon='/img/home-page/sliders-icons/richy-games.svg'
      title={t('home_page_richy_games_title')}
      linkToAll={linkToAll}
      settings={settings}
    >
      {games?.map(( game ) => (
        <GameCard key={game?.id}
          game={game}
        />
      ))}
    </CardsSlider>
  )
}

export default RichyGames
