import { FC, useMemo } from 'react'

import { useTranslation } from 'next-i18next'
import { useLayoutContext } from 'context/layoutContext'
import CardsSlider from 'components/pages-blocks/Components/CardSlider'
import GameCard from 'components/pages-blocks/Components/GameCard'

import { getSettings } from './helper'
import { IGamesSlider } from './types'
import { SSection } from './styles'

const HotGames: FC<IGamesSlider> = ({ games }) => {
  const linkToAll = '/catalog/category/25'
  const { t } = useTranslation()

  const { contentSize } = useLayoutContext()
  const settings = useMemo(() => getSettings(contentSize), [contentSize])

  return (
    <SSection>
      <CardsSlider
        total={games?.length || 0}
        title={t('home_page_hot_games_title')}
        icon='/img/home-page/sliders-icons/hot-games.svg'
        linkToAll={linkToAll}
        settings={settings}
      >
        {games?.map((game) => (
          <GameCard key={game?.id}
            severalRowsInMobile
            game={game}
          />
        ))}
      </CardsSlider>
    </SSection>
  )
}

export default HotGames
