import { FC, useState } from 'react'

import { useFormik, FormikProvider } from 'formik'
import { SvgIcon } from 'ui-kit/svg'
import { COLORS } from 'ui-kit/constants'
import { useQuery } from '@tanstack/react-query'
import { fetchProviders } from 'components/for_pages/HomePage/requests'
import { SearchSvg } from 'components/svg/SearchSvg'
import { IGameProvider } from 'data/interfaces/IGameProvider'

import ProviderCard from './ProviderCard'
import { SProvidersContent, SProvidersWrapper, SInput, SInputWrapper, SCloseBtn, SProvidersList, SSearchIcon } from './styles'


const ProvidersBlock: FC<{ setOpen: (state: boolean) => void, providers: IGameProvider[] }> = ({ setOpen, providers }) => {
  const formik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: () => undefined
  })
  const [isInputFocused, setInputFocused] = useState(false)

  const handleInputFocus = () => setInputFocused(true)
  const handleInputBlur = () => setInputFocused(false)

  const { data } = useQuery(
    ['providers', formik?.values?.search],
    () => fetchProviders({ name: formik?.values?.search || '' }),
    { initialData: {
      data: providers || []
    } }
  )

  const handleCloseBlock = () => setOpen(false)

  return (
    <SProvidersWrapper>
      <SProvidersContent>
        <SInputWrapper>
          <SCloseBtn  onClick={handleCloseBlock}>
            <SvgIcon type='close'
              color={COLORS.dark200}
            />
          </SCloseBtn>

          <SSearchIcon>
            <SearchSvg color={isInputFocused ? 'white' : '#686A7B'} />
          </SSearchIcon>

          <FormikProvider value={formik}>
            <SInput
              name='search'
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
          </FormikProvider>

          <SProvidersList>
            {data?.data?.map((provider) => (
              <ProviderCard key={provider.id}
                provider={provider}
              />
            ))}
          </SProvidersList>
        </SInputWrapper>
      </SProvidersContent>
    </SProvidersWrapper>
  )
}

export default ProvidersBlock
