import { useRef } from 'react'

import Slider from 'react-slick'
import { useAppContext } from 'context/AppContext'
import { useLayoutContext } from 'context/layoutContext'
import { SvgIcon } from 'ui-kit/svg'
import { COLORS } from 'ui-kit/constants'

import { settings } from './helper'
import { SArrowLeft, SArrowRight, SBonusesWrapper, SLeftButton, SRightButton, SSliderWrapper } from './styles'
import Slide from './Slide'


const BonusesSlider = () => {
  const { banners } = useAppContext()
  const { contentSize } = useLayoutContext()
  const sliderRef = useRef<Slider | null>(null)
  const handlePrev = () => sliderRef.current?.slickPrev()
  const handleNext = () => sliderRef.current?.slickNext()

  return (
    <SBonusesWrapper contentSize={contentSize}>
      <SLeftButton
        onClick={handlePrev}
        color='dark'
        mode='transparent'
        size='small'
      >
        <SArrowLeft>
          <SvgIcon type='dropdown-arrow'
            color={COLORS.white}
          />
        </SArrowLeft>
      </SLeftButton>

      <SRightButton
        onClick={handleNext}
        color='dark'
        size='small'
        mode='transparent'
      >
        <SArrowRight>
          <SvgIcon color={COLORS.white}
            type='dropdown-arrow'
          />
        </SArrowRight>
      </SRightButton>

      <SSliderWrapper>
        <Slider
          {...settings}
          ref={(slider) => (sliderRef.current = slider)}
        >
          {banners?.map((banner) => (
            <Slide
              key={banner.id}
              slide={banner}
            />
          ))}
        </Slider>
      </SSliderWrapper>
    </SBonusesWrapper>
  )
}

export default BonusesSlider
