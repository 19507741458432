import styled from 'styled-components'
import { COLORS, BREAKPOINTS, FONTS } from 'ui-kit/constants'


export const SBanerWrapper = styled.div`
  background-image: url('/img/BonusSlide/banner-main-bg.png');
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  overflow: hidden;
  padding: 42px 24px;
  background-repeat: no-repeat;
  background-size: cover;

  //&.with-timer {
  //  background-image: url('/img/BonusSlide/banner-with-timer-bg.jpg');
  //}

  @media screen and (max-width: ${BREAKPOINTS.xsMedium - 1}px) {
    background-image: none;
    background: linear-gradient(180deg, #14151B 0%, rgba(67,144,55, 0.6) 40%,  rgba(20,21,27, 0.4) 90%);
    padding: 0 24px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    //&.with-timer {
    //  background-image: url('/img/BonusSlide/banner-with-timer-bg-mobile.jpg');
    //  padding-top: 186px;
    //  background-size: cover;
    //  background-position-y: -16px;
    //  background-repeat: no-repeat;
    //}
  }
`

export const SBannerText = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 24px;

  @media screen and (max-width: ${BREAKPOINTS.xsMedium - 1}px) {
    margin-bottom: 16px;
    margin-top: -24px;

    ${SBanerWrapper}.with-timer & {
      margin-top: 0;
    }
  }
`

export const SRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: baseline;

  @media screen and (max-width: ${BREAKPOINTS.xsMedium - 1}px) {
    display: flex;
    justify-content: center;
  }
`

export const SDepositBonus = styled.div`
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;
  max-width: 415px;

  @media screen and (max-width: ${BREAKPOINTS.xsMedium - 1}px) {
    font-size: 24px;
  }
`

export const SPercents = styled.div`
  color: ${COLORS.yellow};
  font-size: 40px;
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;

  @media screen and (max-width: ${BREAKPOINTS.xsMedium - 1}px) {
    font-size: 24px;
  }
`

export const SUpTo = styled.div`
  font-size: 40px;
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;

  @media screen and (max-width: ${BREAKPOINTS.xsMedium - 1}px) {
    font-size: 24px;
  }
`

export const SSumOfMoney = styled.div`
  color: ${COLORS.yellow};
  font-size: 40px;
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;

  @media screen and (max-width: ${BREAKPOINTS.xsMedium - 1}px) {
    font-size: 24px;
  }
`

export const SFreeSpins = styled.div`
  font-size: 20px;
  font-weight: 900;
  line-height: 100%;
  text-transform: capitalize;

  @media screen and (max-width: ${BREAKPOINTS.xsMedium - 1}px) {
    display: flex;
    justify-content: center;
    gap: 4px;
  }
`

export const SLotteryTickets = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
  text-transform: capitalize;
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;

  @media screen and (max-width: ${BREAKPOINTS.xsMedium - 1}px) {
    justify-content: center;
    padding-top: 0;
  }
`

export const SYellowNumber = styled.span`
  color: ${COLORS.yellow};
`

export const SBottomRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
export const SButtonWrapper = styled.div`
  min-width: 168px;
  position: relative;
  display: inline-flex;
  z-index: 2;
`

export const SImage = styled.div`
  position: absolute;
  z-index: 1;
  width: 479px;
  height: 303px;
  bottom: -60px;
  right: -130px;

  @media screen and (max-width: ${BREAKPOINTS.xsMedium - 1}px) {
    position: relative;
    width: 344px;
    height: 204px;
    right: auto;
    bottom: auto;
    top: 0;
  }
`

export const STimerBox = styled.div`
  background: rgba(255, 255, 255, 0.16);
  border-radius: 4px;
  width: 214px;
  height: 44px;
  overflow: hidden;
  position: relative;
  padding-left: 62px;
  display: flex;
  align-items: center;

  @media screen and (max-width: ${BREAKPOINTS.xsMedium - 1}px) {
    padding: 0 12px;
    width: fit-content;
  }
`

export const STimerFinisIn = styled.div`
  min-width: 54px;
  height: 19px;
  background: ${COLORS.yellow};
  border-radius: 4px 0;
  position: absolute;
  top: 0;
  left: 0;
  ${FONTS.a3};
  color: #14151B;
  display: flex;
  align-items: center;
  padding: 0 8px;

  @media screen and (max-width: ${BREAKPOINTS.xsMedium - 1}px) {
    display: none;
  }
`

export const STimerValuesBox = styled.div`
  display: flex;
  align-items: center;
`

export const STimerColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const STimerValue = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  color: white;
  line-height: 1;
`

export const STimerLabel = styled.p`
  ${FONTS.a3};
  color: white;
`

export const STimerDivider = styled.div`
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  color: white;
  margin: 0 9px;
`



