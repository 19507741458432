import styled, { css } from 'styled-components'
import { BREAKPOINTS, COLORS, FONTS } from 'ui-kit/constants'
import { applyStyles, SIZES } from 'context/layoutContext'


export const SBannerWrapper = styled.div`
  cursor: pointer;
  background-color: ${COLORS.dark600};
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  transition: 0.1s;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE, SIZES.W512], theme.contentSize, css`
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 0;
      box-sizing: border-box;
      height: 147px;
      padding: 16px;
    `)}

    ${applyStyles([SIZES.W688], theme.contentSize, css`
      padding: 24px;
    `)}

    ${applyStyles([SIZES.W1040], theme.contentSize, css`
      padding: 24px 24px 42px;
    `)}
  `};

  &:hover {
    background-color: ${COLORS.dark550};
  }
`

export const SBannerBgElipse = styled.div`
  background-color: #6E1CF5;
  border-radius: 356px;
  position: absolute;
  z-index: 1;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE, SIZES.W512], theme.contentSize, css`
      filter: blur(30px);
      height: 170px;
      right: 0;
      top: -100px;
      width: 170px;
    `)}

    ${applyStyles([SIZES.W688], theme.contentSize, css`
      filter: blur(50px);
      height: 150px;
      right: -60px;
      top: -70px;
      width: 200px;
    `)}

    ${applyStyles([SIZES.W1040], theme.contentSize, css`
      filter: blur(50px);
      height: 200px;
      right: -40px;
      top: -70px;
      width: 300px;
    `)}
  `};
`

export const STextBox = styled.div`
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE, SIZES.W512], theme.contentSize, css`
      max-width: 100%;
      text-align: center;
    `)}

    ${applyStyles([SIZES.W688], theme.contentSize, css`
      max-width: 200px;
    `)}

    ${applyStyles([SIZES.W1040], theme.contentSize, css`
      max-width: 250px;
    `)}
  `};
`

export const STitle = styled.div`
  ${FONTS.h2};

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE, SIZES.W512], theme.contentSize, css`
      ${FONTS.h2};
      margin-bottom: 16px;
    `)}

    ${applyStyles([SIZES.W688, SIZES.W1040], theme.contentSize, css`
      ${FONTS.h0};
    `)}
  `}
`

export const SDescription = styled.div`
  ${FONTS.p1}
  color: ${COLORS.dark80};

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    display: none;
  }
`

export const SImgWrapper = styled.div`
  height: auto;
  z-index: 2;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE, SIZES.W512], theme.contentSize, css`
      position: relative;
      top: 0;
      right: 20px;
      width: 150%;
      max-width: 176px;
    `)}

    ${applyStyles([SIZES.W688], theme.contentSize, css`
      position: absolute;
      right: -60px;
      top: 10px;
      width: 235px;
  `)}

    ${applyStyles([SIZES.W1040], theme.contentSize, css`
      position: absolute;
      right: 28px;
      width: 233px;
      top: 10px;
    `)}
  `};
`
