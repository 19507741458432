import { FC } from 'react'

import Image from 'next/image'
import Link from 'next/link'
import { IBanner } from 'data/interfaces/IBanner'

import { SBgImage, SSlide, STitle } from './styles'

interface ISlideProps {
  slide: IBanner
}

const Slide: FC<ISlideProps> = ({ slide }) => {
  return (
    <Link href={slide.redirectUrl}>
      <SSlide>
        <STitle>
          {slide.title}
        </STitle>

        <SBgImage>
          <Image
            src={slide.imageDesktopUrl}
            quality={65}
            layout='fill'
            objectFit='cover'
          />
        </SBgImage>

        <SBgImage className='mobile'>
          <Image
            src={slide.imageMobileUrl}
            quality={65}
            layout='fill'
            objectFit='cover'
          />
        </SBgImage>
      </SSlide>
    </Link>
  )
}

export default Slide

