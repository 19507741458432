import { SNavigationsBannerSection } from './styles'
import CasinoBanner from './CasinoBanner'
import SportBanner from './SportBanner'


const NavigationsBannerSection = () => {
  return (
    <SNavigationsBannerSection>
      <CasinoBanner />

      <SportBanner />
    </SNavigationsBannerSection>
  )
}

export default NavigationsBannerSection
