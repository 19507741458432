import { useState } from 'react'

import { useTranslation } from 'next-i18next'
import { useQuery } from '@tanstack/react-query'
import Image from 'next/image'
import { IGameHistory } from 'data/interfaces/IGameHistory'

import { fetchGameSessionHistoryLastRows } from '../../for_pages/HomePage/requests'

import { SStatisticsContainer, SStatisticField, SHeader, SSliderTitle } from './styles'
import Item from './Item'


interface StatisticsProps {
  statistics: IGameHistory[];
}

const Statistics: React.FC<StatisticsProps> = ({ statistics }) => {
  const [data, setData] = useState(statistics)
  const { t } = useTranslation()

  useQuery(
    ['fetchGameSessionHistoryLastRows'],
    () => fetchGameSessionHistoryLastRows(1),
    {
      refetchInterval: 3000,
      staleTime: Infinity,
      select: ({ data }: { data: IGameHistory[]}) => data || [],
      onSuccess: (newData: IGameHistory[]) => setData((prevData) => ([...newData, ...prevData.slice(0, prevData.length - 1)])),
    }
  )

  return (
    <section>
      <SSliderTitle>
        <Image
          width={20}
          height={20}
          quality={65}
          src='/img/Statistics/stats.svg'
        />

        <div>
          {t('home_page_statistic_title')}
        </div>
      </SSliderTitle>

      <SStatisticsContainer>
        <SHeader>
          <SStatisticField>
            {t('home_page_statistic_game')}
          </SStatisticField>

          <SStatisticField>
            {t('home_page_statistic_player')}
          </SStatisticField>

          <SStatisticField>
            {t('home_page_statistic_multiplier')}
          </SStatisticField>

          <SStatisticField>
            {t('home_page_statistic_payout')}
          </SStatisticField>
        </SHeader>

        <div>
          {data?.map((item) => (
            <Item key={`${item.amountWin}-${item.userId}-${item.gameId}`}
              item={item}
            />
          ))}
        </div>
      </SStatisticsContainer>
    </section>
  )
}

export default Statistics
