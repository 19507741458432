import React, { FC } from 'react'

import Link from 'next/link'
import Image from 'next/image'
import { Routes } from 'types/routes'
import { IGameProvider } from 'data/interfaces/IGameProvider'

import { SGameProvider, SImageWrapper, SItem } from './styles'


const ProviderCard: FC<{ provider: IGameProvider }> = ({ provider }) => {
  return (
    <SItem>
      <Link href={Routes.catalogProvider(provider.id)}>
        <SGameProvider>
          <SImageWrapper>
            {provider.imagePreviewUrl && (
              <Image
                src={provider.imagePreviewUrl}
                width={92}
                height={42}
              />
            )}
          </SImageWrapper>
        </SGameProvider>
      </Link>
    </SItem>
  )
}

export default ProviderCard
