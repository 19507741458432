import styled, { css } from 'styled-components'
import { applyStyles, SIZES } from 'context/layoutContext'


export const SNavigationsBannerSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  margin: 0 12px;
  margin-bottom: 24px;

  ${({ theme }) => `
    ${applyStyles([SIZES.W512, SIZES.W688, SIZES.W1040], theme.contentSize, css`
      margin: 0;
      margin-bottom: 24px;
    `)}
  `};

  ${({ theme }) => `
    ${applyStyles([SIZES.W512], theme.contentSize, css`
      grid-gap: 8px;
    `)}
  `};
`
