import Image from 'next/image'
import { Button } from 'ui-kit'
import { useTranslation } from 'next-i18next'
import { useAppContext } from 'context/AppContext'
import { ModalType, ProfileModalType } from 'types/enums'
import { pluralize } from 'numeralize-ru'
import Formatter, { pad } from 'utils/formatter'
import { useLoyaltyContext } from 'context/loyaltyContext'
import { PrivelegyIdent } from 'data/interfaces/ILoyalty'
import classNames from "classnames"
import { useTimer } from "react-timer-hook"
import { useQuery } from "@tanstack/react-query"

import { getBonusMaxAmount } from "../../../../utils/loyaltyUtils"

import {
  SBannerText,
  SBanerWrapper,
  SDepositBonus,
  SFreeSpins,
  SLotteryTickets,
  SPercents,
  SRow,
  SSumOfMoney,
  SUpTo,
  SYellowNumber,
  SButtonWrapper,
  SImage,
  SBottomRow,
  STimerBox,
  STimerFinisIn,
  STimerValuesBox,
  STimerColumn,
  STimerValue, STimerLabel, STimerDivider
} from './styles'

const DepositBonus = () => {
  const { t } = useTranslation()
  const appContext = useAppContext()
  const loyaltyContext = useLoyaltyContext()

  const handleSignUp = () => appContext.showModal(ModalType.registration)
  const handleDeposit = () => appContext.showModalProfile(ProfileModalType.wallet)

  const data = loyaltyContext.indexPageBonus
  const relativeAmountBonus = data?.privileges?.[PrivelegyIdent.relativeAmount] && data.privileges?.[PrivelegyIdent.relativeAmount][data.privileges?.[PrivelegyIdent.relativeAmount].length - 1]
  const freeSpinsBonus = data?.privileges?.[PrivelegyIdent.freeSpins] && data?.privileges?.[PrivelegyIdent.freeSpins][data?.privileges?.[PrivelegyIdent.freeSpins].length - 1]
  const lotteryTicketsBonus = data?.privileges?.[PrivelegyIdent.lotteryTickets] && data?.privileges?.[PrivelegyIdent.lotteryTickets][data?.privileges?.[PrivelegyIdent.lotteryTickets].length - 1]

  const withTimer = data?.validTill

  const { data: defaultCurrency } = useQuery(['fetchDefaultCurrency'], () => appContext.fetchDefaultCurrency())

  const userCurrency = appContext.auth ? appContext.currencies.find(
    (currency) => currency.iso === appContext?.user?.currencyIso,
  ) : defaultCurrency
  const maxBonusAmount = getBonusMaxAmount(relativeAmountBonus, userCurrency)

  const handleExpire = () => {
    loyaltyContext.refetchBonuses()
  }

  return (
    <SBanerWrapper className={classNames({ 'with-timer': withTimer })}>
      {/*{!withTimer && (*/}

      <SImage>
        <Image
          quality={60}
          src='/img/BonusSlide/mobile-bg.png'
          layout='fill'
        />
      </SImage>

      {/*)}*/}

      {
        !data && (
          <SBannerText>
            <SDepositBonus>
              {t('bonus_empty_title')}
            </SDepositBonus>
          </SBannerText>
        )}

      {
        !!data && (
          <SBannerText>
            <SRow>
              <SDepositBonus>
                {t('bonus_title')}
              </SDepositBonus>

              <SPercents>
                {!!relativeAmountBonus && relativeAmountBonus.percent + '%'}
              </SPercents>
            </SRow>

            <SRow>
              <SUpTo>
                {t('bonus_up_to')}
              </SUpTo>

              <SSumOfMoney>
                {maxBonusAmount.inCurrentCurrency ? `${Formatter.formatNumber(maxBonusAmount.value)} ${userCurrency?.iso}` : `${maxBonusAmount.value} USD`}
              </SSumOfMoney>
            </SRow>

            {freeSpinsBonus && (
              <SFreeSpins>
                <SYellowNumber>
                  {`+${Formatter.formatNumber(freeSpinsBonus.count)} `}
                </SYellowNumber>

                {t('bonus_fs')}
              </SFreeSpins>
            )}

            <SLotteryTickets>
              <SYellowNumber>
                {!!lotteryTicketsBonus && Formatter.formatNumber(lotteryTicketsBonus.count)}
              </SYellowNumber>

              {!!lotteryTicketsBonus && pluralize(lotteryTicketsBonus.count, t('bonus_lottery_1'), t('bonus_lottery_2'), t('bonus_lottery_5'))}
            </SLotteryTickets>
          </SBannerText>
        )}

      <SBottomRow>
        <SButtonWrapper>
          {!appContext.auth && (
            <Button onClick={handleSignUp}>
              {t('bonus_sign_up')}
            </Button>
          )}

          {!!appContext.auth && (
            <Button onClick={handleDeposit}>
              {t('profile_deposit')}
            </Button>
          )}
        </SButtonWrapper>

        {
          withTimer && !!data.validTill && (
            <Timer
              expiredAt={data.validTill}
              onExpire={handleExpire}
            />
          )}

      </SBottomRow>

    </SBanerWrapper>
  )
}

interface ITimerProps {
  expiredAt: number,
  onExpire: () => void
}

const Timer: React.FC<ITimerProps> = ({ expiredAt, onExpire }) => {
  const { t } = useTranslation()

  const expireDate = new Date(expiredAt)

  const {
    minutes,
    hours,
    seconds
  } = useTimer({ expiryTimestamp: expireDate, onExpire })

  return (
    <STimerBox>
      <STimerFinisIn>
        Finish in
      </STimerFinisIn>

      <STimerValuesBox>
        <STimerColumn>
          <STimerValue>
            { pad('00',
              hours
            )}
          </STimerValue>

          <STimerLabel>
            {pluralize(hours, t('timer_hours_1'), t('timer_hours_2'), t('timer_hours_5'))}
          </STimerLabel>
        </STimerColumn>

        <STimerDivider>
          :
        </STimerDivider>

        <STimerColumn>
          <STimerValue>
            { pad('00',
              minutes
            )}
          </STimerValue>

          <STimerLabel>
            {pluralize(minutes, t('timer_mins_1'), t('timer_mins_2'), t('timer_mins_5'))}
          </STimerLabel>
        </STimerColumn>

        <STimerDivider>
          :
        </STimerDivider>

        <STimerColumn>
          <STimerValue>
            { pad('00',
              seconds
            )}
          </STimerValue>

          <STimerLabel>
            {pluralize(seconds, t('timer_secs_1'), t('timer_secs_2'), t('timer_secs_5'))}
          </STimerLabel>
        </STimerColumn>
      </STimerValuesBox>
    </STimerBox>
  )
}

export default DepositBonus
