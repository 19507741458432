import { FC } from 'react'

import Image from 'next/image'
import { IGameHistory } from 'data/interfaces/IGameHistory'
import { useAppContext } from 'context/AppContext'
import { runtimeConfig } from 'config/runtimeConfig'

import {
  SAmountWin, SCoefficient, SCurrencyImg, SHidingText, SIconWrapper, SStatisticField, SStatisticsItem
} from './styles'

interface ItemProps {
  item: IGameHistory
}

const Item: FC<ItemProps> = ({ item }) => {
  const appContext = useAppContext()

  const currencyImg = appContext.currencies.find(c => c.iso === item.currencyIso)?.imageIconSmallUrl

  return (
    <SStatisticsItem>
      <SStatisticField>
        <SIconWrapper>
          <Image
            width={20}
            height={20}
            src={item.imageIconPreviewUrlSquare || '/img/Statistics/game.svg'}
          />
        </SIconWrapper>

        <SHidingText>
          {item.gameName}
        </SHidingText>
      </SStatisticField>

      <SStatisticField>
        <SHidingText>
          {item.nickname}
        </SHidingText>
      </SStatisticField>

      <SStatisticField>
        <SCoefficient coefficient={item.coefficient}>
          {`x${Math.abs(item.coefficient)?.toFixed(2)}`}
        </SCoefficient>
      </SStatisticField>

      <SStatisticField>
        {!!currencyImg && (
          <SCurrencyImg>
            <Image
              width={20}
              height={20}
              src={`${runtimeConfig.HOST}${currencyImg}`}
            />
          </SCurrencyImg>
        )}

        <SAmountWin>
          {item.amountWin}
        </SAmountWin>
      </SStatisticField>
    </SStatisticsItem>
  )
}

export default Item
