import { Settings } from 'react-slick'

export const settings: Settings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 300,
  slidesToShow: 1,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 3000,
  rows: 1,
  lazyLoad: 'progressive',
}
