import styled, { css } from 'styled-components'
import { COLORS, FONTS } from 'ui-kit/constants'
import { applyStyles, SIZES } from 'context/layoutContext'

export const SSliderTitle = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  color: ${COLORS.dark80};
  ${FONTS.h2};
  margin-bottom: 20px;
  display: flex;
  gap: 4px;
`

export const SHeader = styled.div `
  display: grid;
  background: ${COLORS.dark550};
  padding: 20px;
  gap: 8px;
  box-sizing: border-box;
  border-radius: 8px 8px 0 0;
  color: ${COLORS.dark200};
  font-weight: 600;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE], theme.contentSize, css`
      grid-template-columns: 1fr 1fr 40px 100px;
    `)}

    ${applyStyles([SIZES.W512], theme.contentSize, css`
      grid-template-columns: 110px 130px 1fr 110px;
    `)}

    ${applyStyles([SIZES.W688], theme.contentSize, css`
      grid-template-columns: 3fr 2fr 2fr 1fr;
    `)}

    ${applyStyles([SIZES.W1040], theme.contentSize, css`
      grid-template-columns: 3fr 2fr 2fr 1fr;
    `)}
  `};
`

export const SStatisticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const SStatisticsItem = styled.div`
  display: grid;
  gap: 8px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;

  &:nth-child(2n) {
    background: ${COLORS.dark600};
  }

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE], theme.contentSize, css`
      grid-template-columns: 1fr 1fr 50px 100px;
    `)}

    ${applyStyles([SIZES.W512], theme.contentSize, css`
      grid-template-columns: 110px 130px 1fr 110px;
    `)}

    ${applyStyles([SIZES.W688], theme.contentSize, css`
      grid-template-columns: 3fr 2fr 2fr 1fr;
    `)}

    ${applyStyles([SIZES.W1040], theme.contentSize, css`
      grid-template-columns: 3fr 2fr 2fr 1fr;
    `)}
  `};
`

interface SStatisticFieldProps {
  alignRight?: boolean;
}

export const SStatisticField = styled.div<SStatisticFieldProps>`
  display: flex;
  gap: 4px;
  align-items: center;
  max-width: 100%;

  ${({ alignRight }) => (alignRight ? 'justify-content: flex-end;' : '')}

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE], theme.contentSize, css`
      font-size: 12px;
    `)}
  `};
`

export const SCurrencyImg = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  min-width: 20px;
`

export const SIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  overflow: hidden;
`

export const SHidingText = styled.div`
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE], theme.contentSize, css`
      max-width: 50px;
      width: 40px;
      font-size: 12px;
    `)}

    ${applyStyles([SIZES.W512], theme.contentSize, css`
      max-width: 80px;
      width: 80px;
    `)}

    ${applyStyles([SIZES.W688], theme.contentSize, css`
      max-width: 160px;
    `)}

    ${applyStyles([SIZES.W1040], theme.contentSize, css`
      max-width: 300px;
    `)}
  `};
`

interface SCoefficientProps {
  coefficient: number;
}

export const SCoefficient = styled.div<SCoefficientProps>`
  display: flex;
  justify-content: center;
  background: ${COLORS.dark600};
  border-radius: 4px;
  min-width: 55px;
  box-sizing: border-box;
  padding: 4px 12px;
  font-weight: 700;
  color: ${(props) => (props.coefficient > 0 ? COLORS.green : COLORS.red)};

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE], theme.contentSize, css`
      font-size: 12px;
    `)}
  `};
`

export const SAmountWin = styled.div`
  font-weight: 700;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE], theme.contentSize, css`
      font-size: 12px;
    `)}
  `};
`

